import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/layout/seo';
import Layout from '../components/layout/layout';
import SectionWrapper from '../components/layout/sectionWrapper';
import TopSectionScolaires from '../components/sections/eventsIndex/topSectionScolaires';
import FilteringSection from '../components/sections/eventsIndex/filteringSection';
import AboutFeatured from '../components/sections/eventsIndex/featuredAboutSection';
import LocalPartners from '../components/sections/eventsIndex/localPartnersSection';
import { sortEventsByDate } from '../utils/processDate';
import TitleSection from '../components/sections/eventsIndex/titleSection';

const EventsEducation = ({ data }) => {
  const rawEvents = data.allSanityEvent.edges.map(({ node }) => node);
  const events = sortEventsByDate(rawEvents);
  const page = data.sanityPage.pageContent[0];
  const logos = page.logosList;
  const villages = data.villagesScolaires.edges;
  const { featuredTitle, featuredContent } = data.featured.pageContent[0];
  const { villageTitle, villageContent } = data.village.pageContent[0];
  return (
    <Layout>
      <SEO title="Scolaires" />
      <SectionWrapper>
        <TitleSection title={page.topTitle} />
        <FilteringSection events={events} scolaires={true} />
        <TopSectionScolaires
          villages={villages}
          scolaires
          villageTitle={villageTitle}
          villageContent={villageContent}
        />
        <AboutFeatured
          featuredTitle={featuredTitle}
          featuredContent={featuredContent}
        />
        {logos.length > 0 && (
          <LocalPartners logos={logos} partnersTitle={page.partnersTitle} />
        )}
      </SectionWrapper>
    </Layout>
  );
};

export default EventsEducation;

export const query = graphql`
  fragment VillageInfo on SanityVillage {
    id
    title
    _rawDescription
    description {
      children {
        text
      }
    }
    department {
      name
    }
    slug {
      current
    }
    timeSlots {
      endDate
      endTime
      startDate
      startTime
    }
    image {
      asset {
        gatsbyImageData(width: 1200)
      }
      hotspot {
        x
        y
      }
    }
  }

  query AllEducation {
    allSanityEvent(filter: { education: { eq: true } }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          department {
            name
            id
          }
          featured
          eventCanceled
          village {
            id
            title
            timeSlots {
              endDate
              endTime
              startDate
              startTime
              isEducation
            }
          }
          _rawDescription
          description {
            children {
              text
            }
          }
          theme {
            id
            name
          }
          timeSlots {
            endDate
            endTime
            startDate
            startTime
            isEducation
          }
          format {
            id
            name
          }
          image {
            asset {
              gatsbyImageData(width: 450)
            }
            hotspot {
              x
              y
            }
          }
          audience {
            id
            name
          }
          audienceCustom {
            from
            to
          }
        }
      }
    }
    sanityPage(pageName: { eq: "Scolaires" }) {
      pageContent {
        ... on SanityScolairesPageBlock {
          topTitle
          partnersTitle
          logosList {
            image {
              asset {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
    featured: sanityPage(pageName: { eq: "Coups de Coeur" }) {
      pageContent {
        ... on SanityFeaturedBlock {
          featuredTitle
          featuredContent
        }
      }
    }
    village: sanityPage(pageName: { eq: "Village des Sciences" }) {
      pageContent {
        ... on SanityVillageBlock {
          villageContent
          villageTitle
        }
      }
    }
    villagesScolaires: allSanityVillage(filter: {scolaire: {eq: true}}) {
      edges {
        node {
          ...VillageInfo
        }
      }
    }
  }
`;
